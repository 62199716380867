import { useState, useEffect, useRef } from 'react';
import type { ChangeEvent } from 'react';
import styled, { css, CSSObject } from 'styled-components';
import { SearchOutlineIc, CloseOutlineIc } from '@dsch/dd-icons';

import { media } from 'helpers/breakpoints';
import { LinkButton } from 'components/Toolkit/Button/LinkButton';
import { SearchItem } from 'components/Toolkit/SearchItem/SearchItem';
import { LastSearchCard } from 'components/PageWrappers/SharedComponents/LastSearchCard/LastSearchCard';

import type { TLastSearch } from 'components/PageWrappers/SharedComponents/LastSearchCard/types';
import type {
  IAutoCompleteSuggestion,
  TAutoCompleteSearchRequest,
} from 'types';
import { useOnUpdateOnly } from 'hooks/UseOnUpdateOnly';
import { CDN_STATIC_ASSETS } from 'utils';

type SearchVariant = 'MODAL' | 'INLINE';

export interface IHandleSelectSearchItem {
  autoSuggestedText: string;
  searchRequest?: TAutoCompleteSearchRequest;
  url?: string;
  category?: string;
  tracking?: string;
  searchType?: string;
}

export interface BaseSearchProps {
  variant: SearchVariant;
  placeholder?: string;
  presetInput?: string;
  autoFocus?: boolean;
  autoSuggestDisplayCount?: number;
  initialSearchValues?: IAutoCompleteSuggestion[];
  autoSuggestItems?: IAutoCompleteSuggestion[];
  onFocus?: () => void;
  onBlur?: () => void;
  onClose?: () => void;
  onClear?: () => void;
  onInput: (t: string) => void;
  onHandleSearch: (t: string) => void;
  onHandleSelectSearchItem: ({
    autoSuggestedText,
    searchRequest,
    url,
    category,
    tracking,
    searchType,
  }: IHandleSelectSearchItem) => void;
  closeOnSearch?: boolean;
  allowEmptySearch?: boolean;
  tracking?: string;
  className?: string;
  styles?: CSSObject;
  lastSearchData?: TLastSearch;
  lastSearchCallback?: () => void;
  lastSearchDataTrackingValue?: string;
}

const InlineWrapperOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.BLACK};
  opacity: 0.48;
  z-index: ${({ theme }) => theme.zIndices.SEARCH_CONTAINER_OVERLAY};

  ${media.medium} {
    display: none;
  }
`;

interface IWrapper {
  isFocus: boolean;
  variant: SearchVariant;
}
const Wrapper = styled.div<IWrapper>`
  ${({ theme, variant }) =>
    variant === 'INLINE' && `border-radius: ${theme.borderRadius.default}`};
  ${({ variant }) => variant === 'INLINE' && 'position: relative; top: 0;'};
`;

interface IStyledSearchComponent {
  variant: SearchVariant;
}

interface ISearchContainer extends IStyledSearchComponent {
  hasItems: boolean;
}
const SearchContainer = styled.div<ISearchContainer>`
  position: relative;
  ${({ variant }) =>
    variant === 'MODAL' &&
    css`
      padding: ${({ theme }) => `${theme.spacing.M16} ${theme.spacing.S8}`};
    `}
  display: grid;
  grid-template-columns: ${({ variant }) =>
    variant === 'MODAL' ? '1fr auto' : '1fr'};
  grid-column-gap: ${({ theme }) => theme.spacing.S8};
  ${({ theme, variant }) =>
    variant === 'INLINE' && `background-color: ${theme.colors.WHITE}`};
  z-index: ${({ theme }) => theme.zIndices.SEACH_CONTAINER_INPUT};

  border-radius: ${({ theme, variant, hasItems }) =>
    variant === 'INLINE'
      ? !hasItems
        ? theme.borderRadius.default
        : '4px 4px 0 0'
      : theme.borderRadius.default};

  ${media.large} {
    padding: ${({ theme, variant }) =>
      variant === 'MODAL' ? `${theme.spacing.M16}` : '0'};
    grid-column-gap: ${({ theme }) => theme.spacing.M16};
    z-index: 0;
  }
`;

interface IInputContainer extends IStyledSearchComponent {
  isFocus: boolean;
}
export const InputContainer = styled.div<IInputContainer>`
  height: ${({ theme }) => theme.spacing.L40};
  border-radius: ${({ theme, isFocus, variant }) =>
    variant === 'INLINE' && isFocus
      ? '4px 4px 0 0'
      : theme.borderRadius.default};
  border-width: 1px;
  border-style: solid;
  ${({ theme, isFocus, variant }) =>
    isFocus
      ? variant === 'MODAL'
        ? `border-color: ${theme.colors.BLUE};`
        : `border-color: transparent`
      : `border-color: ${theme.colors.GREY};`};
  padding: 0 ${({ theme }) => theme.spacing.S8};

  ${media.medium} {
    height: ${({ theme }) => theme.spacing.L48};
    padding: ${({ theme, variant }) =>
      variant === 'MODAL'
        ? `0 ${theme.spacing.S12}`
        : `0 ${theme.spacing.M16}`};
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: ${({ theme }) => theme.spacing.S8};
  align-items: center;
  height: 100%;
`;

const SearchIcon = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 100%;
  line-height: 1;
  vertical-align: middle;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.GREY_DARKER};
  }

  &:focus {
    outline: none;
  }
`;

const SInput = styled.input<IStyledSearchComponent>`
  outline: none;
  border: 0px;
  padding: 0px;
  background-color: transparent;
  ${({ theme }) => theme.fontSize.M16};

  ${media.large} {
    ${({ theme, variant }) =>
      variant === 'MODAL' ? theme.fontSize.L18 : theme.fontSize.M16}
  }
`;

const CloseIcon = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 100%;
  line-height: 1;
  vertical-align: middle;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.GREY_DARKER};

  // Touch target
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${({ theme }) => theme.spacing.L40};
    height: ${({ theme }) => theme.spacing.L40};
    transform: translate(-50%, -50%);
    content: '';

    ${media.medium} {
      width: ${({ theme }) => theme.spacing.M24};
      height: ${({ theme }) => theme.spacing.M24};
    }
  }

  &:focus {
    outline: none;
    color: ${({ theme }) => theme.colors.BLUE};
  }
`;

interface ISearchItemsContainer extends IStyledSearchComponent {
  autoSuggestDisplayCount: number;
  hasItems: boolean;
}
export const SearchItemsContainer = styled.div<ISearchItemsContainer>`
  ${({ variant, theme, hasItems, autoSuggestDisplayCount }) =>
    variant === 'MODAL'
      ? css`
          overflow-y: auto;
          height: calc(100vh - 72px);
          padding-bottom: ${hasItems && theme.spacing.M16};

          ${media.large} {
            height: initial;
            max-height: calc(${autoSuggestDisplayCount} * 42px);
          }
        `
      : css`
          position: absolute;
          top: 40px;
          left: 0;
          right: 0;
          z-index: 9990;
          height: initial;
          max-height: calc(${autoSuggestDisplayCount} * 42px);
          background-color: ${theme.colors.WHITE};
          border-radius: 0 0 4px 4px;
          border-bottom-right-radius: 4px !important;

          ${media.medium} {
            top: 48px;
          }
        `}
`;

const SearchHeader = styled.p<IStyledSearchComponent>`
  margin-left: ${({ theme }) => theme.spacing.S8};
  ${({ theme }) => theme.fontSize.S12}

  ${media.large} {
    ${({ theme }) => theme.fontSize.M14}
    margin-left: ${({ theme }) => theme.spacing.M16};
    ${({ theme, variant }) =>
      variant === 'INLINE' && `padding-top: ${theme.spacing.S8}`};
  }
`;

const StyledLastSearchCard = styled(LastSearchCard)`
  box-shadow: none;
  border-radius: 0;
`;

function BaseSearch({
  variant,
  placeholder,
  presetInput,
  autoSuggestDisplayCount = 10,
  autoFocus = false,
  initialSearchValues,
  autoSuggestItems,
  onFocus,
  onBlur,
  onClose,
  onClear,
  onInput,
  onHandleSearch,
  onHandleSelectSearchItem,
  closeOnSearch = false,
  allowEmptySearch = false,
  tracking,
  className,
  styles,
  lastSearchData,
  lastSearchCallback,
  lastSearchDataTrackingValue,
}: BaseSearchProps) {
  const ref = useRef<HTMLDivElement>(null);
  const outerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string>(presetInput ?? '');
  const [activeSearchItem, setActiveSearchItem] = useState<number>(0);
  const [isFocus, setIsFocus] = useState<boolean>(autoFocus);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useOnUpdateOnly(() => {
    if (typeof presetInput !== 'undefined' && presetInput !== inputValue) {
      setInputValue(presetInput);
    }
  }, [presetInput]);

  useEffect(() => {
    window.addEventListener('keydown', handleArrowPress);

    return () => window.removeEventListener('keydown', handleArrowPress);
  }, [handleArrowPress]);

  useEffect(() => {
    if (isOpen && variant !== 'MODAL') {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      variant !== 'MODAL' &&
        document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      variant !== 'MODAL' &&
        document.removeEventListener('mousedown', handleClickOutside);
    };
    // TODO Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  function handleSearchType(url: string) {
    if (url.includes('?words=')) {
      return 'global_keyword_search';
    } else {
      return 'global_structured_search';
    }
  }

  function onSelectSearchTerm({
    autoSuggestedText,
    searchRequest,
    url,
    category,
    tracking,
    searchType,
  }: IHandleSelectSearchItem) {
    onHandleSelectSearchItem({
      autoSuggestedText,
      searchRequest,
      url,
      category,
      tracking,
      searchType,
    });
    if (closeOnSearch) {
      close();
    }
    setInputValue(autoSuggestedText);
  }

  function onSearch(value: string) {
    onHandleSearch(value);

    if (closeOnSearch) {
      close();
    }
  }

  function handleClickOutside(event: MouseEvent) {
    if (
      (ref.current && ref.current.contains(event.target as Node)) ||
      (outerRef.current && outerRef.current.contains(event.target as Node))
    ) {
      return;
    }

    close();
  }

  function close() {
    setIsOpen(false);
    setIsFocus(false);
    handleClose();
    inputRef.current && inputRef.current.blur();
  }

  // TODO Clean up this function's usage in an effect. We're disabling this lint error for now so
  // we can clean up the lint logs. Ideally we should rewrite this code to be less error prone and
  // trust the lint rule's judgement.
  // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606∑
  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleArrowPress(event: KeyboardEvent) {
    if (isFocus && autoSuggestItems?.length) {
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          if (activeSearchItem !== 0) {
            setActiveSearchItem(activeSearchItem - 1);
            ref.current?.scrollBy(0, -40);
          }
          break;
        case 'ArrowDown':
          event.preventDefault();
          if (
            (autoSuggestItems &&
              activeSearchItem !== autoSuggestItems.length) ||
            (initialSearchValues &&
              !inputValue &&
              activeSearchItem !== initialSearchValues.length)
          ) {
            setActiveSearchItem(activeSearchItem + 1);
            if (activeSearchItem > 8) {
              ref.current?.scrollBy(0, 44);
            }
          }
          break;
      }
    }
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
    onInput(event.target.value);
    setActiveSearchItem(0);
  }

  function handleFocus() {
    setIsFocus(true);
    setIsOpen(true);
    onFocus && onFocus();
  }

  function handleBlur() {
    onBlur && onBlur();
  }

  function handleClose() {
    setActiveSearchItem(0);
    onClose && onClose();
  }

  function handleClear() {
    setInputValue('');
    onInput('');
    setActiveSearchItem(0);
    onClear && onClear();
    inputRef.current && inputRef.current.focus();
  }

  function handleHover(index: number) {
    setActiveSearchItem(index);
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    if (!inputValue && activeSearchItem !== 0 && initialSearchValues) {
      const {
        leftText: autoSuggestedText = '',
        rightText: category,
        url,
        searchRequest,
      } = initialSearchValues[activeSearchItem - 1];
      onSelectSearchTerm({
        autoSuggestedText,
        searchRequest,
        url,
        category,
        tracking,
        searchType: handleSearchType(url),
      });
    }
    if (inputValue && activeSearchItem === 0) {
      onSearch(inputValue);
    } else {
      handleSearch();
    }
  }

  function handleSearch() {
    if (inputValue || allowEmptySearch) {
      if (autoSuggestItems && autoSuggestItems?.length !== 0) {
        const {
          leftText: autoSuggestedText = '',
          rightText: category,
          url,
          searchRequest,
        } = autoSuggestItems[activeSearchItem - 1];
        onSelectSearchTerm({
          autoSuggestedText,
          searchRequest,
          url,
          category,
          tracking,
          searchType: handleSearchType(url),
        });
      } else if (!inputValue && initialSearchValues) {
        const {
          leftText: autoSuggestedText = '',
          rightText: category,
          url,
          searchRequest,
        } = initialSearchValues[activeSearchItem];
        onSelectSearchTerm({
          autoSuggestedText,
          searchRequest,
          url,
          category,
          tracking,
          searchType: handleSearchType(url),
        });
      } else {
        onSearch(inputValue);
      }
    }
  }

  function handleSelectSearchItem(
    autoSuggestedText: string,
    url: string,
    searchRequest: TAutoCompleteSearchRequest,
    category?: string,
  ) {
    onSelectSearchTerm({
      autoSuggestedText,
      searchRequest,
      url,
      category,
      tracking,
      searchType: handleSearchType(url),
    });
  }

  return (
    <Wrapper
      className={className}
      variant={variant}
      isFocus={isFocus}
      ref={outerRef}
    >
      {variant === 'INLINE' && isFocus && (
        <InlineWrapperOverlay onClick={close} />
      )}
      <SearchContainer
        variant={variant}
        hasItems={Boolean(
          isFocus &&
            ((initialSearchValues && !inputValue) ||
              !(
                autoSuggestItems === undefined || autoSuggestItems.length === 0
              )),
        )}
      >
        <InputContainer
          isFocus={isFocus}
          variant={variant}
          style={{ borderRight: styles?.borderRight }}
        >
          <Form onSubmit={handleSubmit}>
            <SearchIcon type="submit">
              <SearchOutlineIc />
            </SearchIcon>
            <SInput
              id="search-box-input"
              name="search-box-input"
              ref={inputRef}
              type="search"
              autoComplete="off"
              placeholder={placeholder}
              onChange={handleChange}
              value={inputValue}
              onFocus={handleFocus}
              onBlur={handleBlur}
              autoFocus={autoFocus}
              variant={variant}
            />
            {inputValue && (
              <CloseIcon type="button" onClick={handleClear}>
                <CloseOutlineIc width={12} height={12} />
              </CloseIcon>
            )}
          </Form>
        </InputContainer>
        {variant === 'MODAL' && (
          <LinkButton ofType="SECONDARY" onClick={handleClose}>
            Cancel
          </LinkButton>
        )}
      </SearchContainer>
      <SearchItemsContainer
        autoSuggestDisplayCount={autoSuggestDisplayCount}
        ref={ref}
        variant={variant}
        data-testid="search-items-container"
        hasItems={Boolean(
          isFocus &&
            ((initialSearchValues && !inputValue) ||
              !(
                autoSuggestItems === undefined || autoSuggestItems.length === 0
              )),
        )}
      >
        {initialSearchValues && !inputValue && isFocus && (
          <>
            {lastSearchData ? (
              <StyledLastSearchCard
                lastSearchData={lastSearchData}
                lastSearchCallback={() => {
                  lastSearchCallback && lastSearchCallback();
                  handleClose();
                }}
                lastSearchDataTrackingValue={lastSearchDataTrackingValue}
              />
            ) : null}
            <SearchHeader variant={variant}>Suggested Searches</SearchHeader>
            {initialSearchValues.map((initialSearchItem, index) => (
              <SearchItem
                key={index + 1}
                index={index + 1}
                searchText={inputValue}
                autoSuggestedText={initialSearchItem.leftText ?? ''}
                sectionText={initialSearchItem.rightText}
                url={initialSearchItem.url}
                handleSelect={handleSelectSearchItem}
                isActive={activeSearchItem === index + 1}
                hoverCallback={handleHover}
                leftIcon={`${CDN_STATIC_ASSETS}/images/illustrations/trending-up-arrow.svg`}
                isInitialValue={true}
                searchRequest={initialSearchItem.searchRequest}
              />
            ))}
          </>
        )}
        {inputValue &&
          autoSuggestItems &&
          isFocus &&
          autoSuggestItems.map((autoSuggestItem, index) => (
            <SearchItem
              key={index + 1}
              index={index + 1}
              searchText={inputValue}
              autoSuggestedText={autoSuggestItem.leftText ?? ''}
              sectionText={autoSuggestItem.rightText}
              sectionTextMuted={autoSuggestItem.rightTextMuted}
              url={autoSuggestItem.url}
              handleSelect={handleSelectSearchItem}
              isActive={activeSearchItem === index + 1}
              hoverCallback={handleHover}
              searchRequest={autoSuggestItem.searchRequest}
            />
          ))}
      </SearchItemsContainer>
    </Wrapper>
  );
}

export { BaseSearch };
